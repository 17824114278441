<template>
    <v-form ref="employment_history">
        <form-section
            :sectionTitle="$t('applications.employment-history')"
            wideFirstCol
        >
            <template v-slot:col-1>
                <div
                    v-for="{
                        employer,
                        meta,
                        type,
                        address,
                        status,
                        editable,
                        searchable,
                        removeable,
                    } in entitiesData"
                    :key="employer.id"
                >
                    <control-tool-bar
                        :type="type"
                        :removeable="removeable"
                        :title="$t('applications.employer')"
                        @remove="removeEntity(employer.id)"
                    ></control-tool-bar>
                    <select-employment-status
                        :type="type"
                        :status="status"
                        @update="updateStatus(employer.id, $event)"
                    >
                    </select-employment-status>
                    <google-address-search
                        v-if="status && editable"
                        :type="type"
                        :prefix="$t('applications.employer-address-prefix')"
                        @update="update(employer.id, 'address', $event)"
                        @searchable="update(employer.id, 'searchable', $event)"
                    ></google-address-search>
                    <div
                        v-if="
                            editable &&
                            ((status && address.address) || !searchable)
                        "
                    >
                        <applicant-address
                            :addressData="address"
                            :unit="false"
                            @update="update(employer.id, 'address', $event)"
                        ></applicant-address>
                        <landlord-employer
                            :modelData="employer"
                            @update="update(employer.id, 'employer', $event)"
                        ></landlord-employer>
                        <v-divider class="mb-3"></v-divider>
                        <employer-meta
                            @update="update(employer.id, 'meta', $event)"
                            :metaData="meta"
                            :type="type"
                        ></employer-meta>
                    </div>
                </div>
                <v-row>
                    <v-col cols="12">
                        <v-alert
                            border="top"
                            outlined
                            prominent
                            icon="mdi-briefcase-account"
                            color="accent"
                            dark
                        >
                            <p>
                                {{ $t("applications.more-than-one-employer") }}
                            </p>
                        </v-alert>
                    </v-col>
                </v-row>
                <v-row>
                    <v-col cols="12" class="text-left mb-3">
                        <v-btn
                            color="secondary"
                            @click.stop="createEntity('employer')"
                            ><v-icon class="mr-2">mdi-plus-circle</v-icon
                            >{{
                                $t("applications.add-current-employer")
                            }}</v-btn
                        >
                    </v-col>
                </v-row>
            </template>
        </form-section>
    </v-form>
</template>

<script>
import InteractsWithEntities from "@components/application/mixins/InteractsWithEntities";

export default {
    name: "employment-history",
    mixins: [InteractsWithEntities],
    components: {
        EmployerMeta: () => import("./EmployerMeta"),
        LandlordEmployer: () => import("./LandlordEmployer"),
        ControlToolBar: () => import("./ControlToolBar"),
        ApplicantAddress: () => import("./ApplicantAddress"),
        SelectEmploymentStatus: () => import("./SelectEmploymentStatus"),
        GoogleAddressSearch: () => import("./GoogleAddressSearch"),
    },
    props: {
        meta: {
            type: Object,
            required: true,
        },
    },
    computed: {},
    data() {
        return {
            name: this.$t("applications.employment-history"),
            entityIdKey: "employer.id",
        };
    },
    watch: {},
    methods: {
        /**
         * Maps the initial load of entities to a setup of params driving the UI
         *
         * @param {Object} elem
         * @param {int} index
         * @return {Object} modified element
         */
        mapEntityElement(elem) {
            // Not searchable if we already have a street address
            elem["searchable"] = elem["address"].street.length === 0;
            elem["editable"] =
                elem["status"] !== "none" &&
                (elem["address"].street.length > 0 ||
                    elem["status"] === "employed");
            // pickup the employment status for the current employer from the employment info from the setup screen if not set
            elem["status"] =
                elem["type"] === "current" &&
                elem["status"] === "" &&
                this.$_.get(this.meta, "employment_info.status", "").length > 0
                    ? this.$_.get(this.meta, "employment_info.status")
                    : elem["status"];
            return elem;
        },
        /**
         * Hook from the mixin to add an entity to the index data
         *
         * @param {any} entityData
         * @return void
         */
        addEntityToIndex(entityData) {
            const index = this.entitiesData
                .slice()
                .reverse()
                .findIndex((item) => item.type === "current");
            this.entitiesData.splice(index, 0, entityData);
        },
        postCreate(entityData) {
            entityData["removeable"] = true;
            entityData["status"] = "employed";
            return entityData;
        },
        /**
         * Updates the status for the address element and creates dynamic label properties for landlord / meta
         *
         * @param {any} id
         * @return void
         */
        updateStatus(id, { status }) {
            let elem = {
                status,
            };
            // Only enable the employer adddress entry for a status of employed
            if (status === "employed") {
                elem["editable"] = true;
                elem["searchable"] = true;
            } else {
                elem["editable"] = false;
                elem["searchable"] = false;
            }
            this.update(id, "root", elem);
        },
        validate() {
            return this.$refs["employment_history"].validate();
        },
    },
};
</script>

<style scoped>
</style>