var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-form",
    { ref: "employment_history" },
    [
      _c("form-section", {
        attrs: {
          sectionTitle: _vm.$t("applications.employment-history"),
          wideFirstCol: ""
        },
        scopedSlots: _vm._u([
          {
            key: "col-1",
            fn: function() {
              return [
                _vm._l(_vm.entitiesData, function(ref) {
                  var employer = ref.employer
                  var meta = ref.meta
                  var type = ref.type
                  var address = ref.address
                  var status = ref.status
                  var editable = ref.editable
                  var searchable = ref.searchable
                  var removeable = ref.removeable
                  return _c(
                    "div",
                    { key: employer.id },
                    [
                      _c("control-tool-bar", {
                        attrs: {
                          type: type,
                          removeable: removeable,
                          title: _vm.$t("applications.employer")
                        },
                        on: {
                          remove: function($event) {
                            return _vm.removeEntity(employer.id)
                          }
                        }
                      }),
                      _c("select-employment-status", {
                        attrs: { type: type, status: status },
                        on: {
                          update: function($event) {
                            return _vm.updateStatus(employer.id, $event)
                          }
                        }
                      }),
                      status && editable
                        ? _c("google-address-search", {
                            attrs: {
                              type: type,
                              prefix: _vm.$t(
                                "applications.employer-address-prefix"
                              )
                            },
                            on: {
                              update: function($event) {
                                return _vm.update(
                                  employer.id,
                                  "address",
                                  $event
                                )
                              },
                              searchable: function($event) {
                                return _vm.update(
                                  employer.id,
                                  "searchable",
                                  $event
                                )
                              }
                            }
                          })
                        : _vm._e(),
                      editable && ((status && address.address) || !searchable)
                        ? _c(
                            "div",
                            [
                              _c("applicant-address", {
                                attrs: { addressData: address, unit: false },
                                on: {
                                  update: function($event) {
                                    return _vm.update(
                                      employer.id,
                                      "address",
                                      $event
                                    )
                                  }
                                }
                              }),
                              _c("landlord-employer", {
                                attrs: { modelData: employer },
                                on: {
                                  update: function($event) {
                                    return _vm.update(
                                      employer.id,
                                      "employer",
                                      $event
                                    )
                                  }
                                }
                              }),
                              _c("v-divider", { staticClass: "mb-3" }),
                              _c("employer-meta", {
                                attrs: { metaData: meta, type: type },
                                on: {
                                  update: function($event) {
                                    return _vm.update(
                                      employer.id,
                                      "meta",
                                      $event
                                    )
                                  }
                                }
                              })
                            ],
                            1
                          )
                        : _vm._e()
                    ],
                    1
                  )
                }),
                _c(
                  "v-row",
                  [
                    _c(
                      "v-col",
                      { attrs: { cols: "12" } },
                      [
                        _c(
                          "v-alert",
                          {
                            attrs: {
                              border: "top",
                              outlined: "",
                              prominent: "",
                              icon: "mdi-briefcase-account",
                              color: "accent",
                              dark: ""
                            }
                          },
                          [
                            _c("p", [
                              _vm._v(
                                " " +
                                  _vm._s(
                                    _vm.$t(
                                      "applications.more-than-one-employer"
                                    )
                                  ) +
                                  " "
                              )
                            ])
                          ]
                        )
                      ],
                      1
                    )
                  ],
                  1
                ),
                _c(
                  "v-row",
                  [
                    _c(
                      "v-col",
                      { staticClass: "text-left mb-3", attrs: { cols: "12" } },
                      [
                        _c(
                          "v-btn",
                          {
                            attrs: { color: "secondary" },
                            on: {
                              click: function($event) {
                                $event.stopPropagation()
                                return _vm.createEntity("employer")
                              }
                            }
                          },
                          [
                            _c("v-icon", { staticClass: "mr-2" }, [
                              _vm._v("mdi-plus-circle")
                            ]),
                            _vm._v(
                              _vm._s(
                                _vm.$t("applications.add-current-employer")
                              )
                            )
                          ],
                          1
                        )
                      ],
                      1
                    )
                  ],
                  1
                )
              ]
            },
            proxy: true
          }
        ])
      })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }